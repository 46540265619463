<template>
  <!-- 项目区域 -->
  <el-dialog class="region" :visible.sync="dialogVisible" width="60%" v-if="dialogVisible" title="区域选择">
  <el-container style="height: 100%">
    <CommonTree
      ref="commonTreeCore"
      treeTitle="区域分类"
      :treeData="treeData"
      nodeKey="id"
      searchTitle="typeName"
      :defaultProps="{ label: 'typeName' }"
      :expandOnClickNode="false"
      @getNodeClick="handleNodeClick"
    />
    <el-main>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <!--  -->
      <grid-layout
        class="itemListGrid"
        ref="gridLayOut"
        :table-options="tableOptions"
        :table-data="tableData"
        :table-loading="tableLoading"
        :data-total="page.total"
        :page="page"
        @page-current-change="handleCurrentChange"
        @page-size-change="handleSizeChange"
        @page-refresh-change="onLoad"
        @gird-handle-select-click="selectionChange"
      >
      </grid-layout>
      <div style="display: flex;justify-content: center" >
        <el-button size="medium" type="primary" @click="confirm" style="margin-right: 24px">确认</el-button>
        <el-button size="medium" @click="closeDialog">取消</el-button>
      </div>
    </el-main>
  </el-container>
  </el-dialog>

</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";

import {
  getAreaClassTree,getAreaClassPage
} from "@/api/safe/projectArea";
import { mapGetters } from "vuex";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
  },

  data() {
    return {
      dialogVisible:false,
      treeData: [],
      treeForm: {
        typeName: "",
        parentId: "",
        id: "",
        prjId: "",
      },
      treeDialogLoading: false,
      nodeData: {},
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},

      treeParentData: [],
      prjId: "", // 项目id
      selectionList: [],
      searchColumns: [
        {
          label: "",
          prop: "areaName",
          span: 4,
          placeholder: "请输入区域名称",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["permission", "userInfo"]),
    tableOptions() {
      return {
        menu:this.$route.query.type != 'view',
        selection: this.$route.query.type != "view",
        column: [
          {
            label: "区域名称",
            prop: "areaName",
            align: "left",
            minWidth: 200,
            overHidden: true,
          },
          {
            label: "区域编码",
            prop: "areaCode",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "工作范围",
            prop: "remark",
            align: "center",
            minWidth: 200,
            overHidden: true,
          },
          {
            label: "更新时间",
            prop: "updateTime",
            align: "center",
            minWidth: 170,
            overHidden: true,
          },
          {
            label: "创建人",
            prop: "createUserName",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "创建人单位",
            prop: "createDeptName",
            align: "left",
            minWidth: 200,
            overHidden: true,
          },
        ],
      };
    },
  },

  methods: {
    init(prjId) {
      this.dialogVisible=true
      // this.prjId = this.userInfo.dept_id;
      this.prjId = prjId
      // this.treeForm.prjId = this.userInfo.dept_id;
      this.getTreeData();
      this.query = {};
      this.onLoad(this.page);
    },
    /** 左侧列表操作 */
    getTreeData() {
      getAreaClassTree({ prjId: this.prjId }).then((res) => {
        this.treeData = res.data.data;
      });
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.query.typeId = data.id;

      this.page.currentPage = 1;
      this.onLoad(this.page);
    },

    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      params.prjId = this.prjId;
      getAreaClassPage(
        page.currentPage,
        page.pageSize,
        Object.assign(page.currentPage, page.pageSize, params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    closeDialog(){
      this.dialogVisible=false;
      this.selectionList=[]
    },
    confirm () {
      if(this.selectionList.length == 0)return this.$message.error('请选择对应区域数据')
      this.$emit('callback',this.selectionList)
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss" >
.region{
  .itemListGrid .avue-crud .el-table {
    height: 450px !important;
    max-height: 450px !important;
    box-sizing: border-box;
  }
  .el-dialog__body{
    height: 600px;
  }
}
</style>
