<template>
  <el-dialog
    title="特殊作业选择"
    :visible.sync="ticketBox"
    v-if="ticketBox"
    width="80%"
    top="10vh"
    class="qmDialog"
  >
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="option"
      :table-data="tableData"
      :table-loading="loading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @gird-handle-select-click="selectionChange"
    >
    </grid-layout>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确定</el-button>
      <el-button @click="closeDialog">{{ $t("cip.cmn.btn.celBtn") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { ticketInventoryList, myWtTicketPage } from "@/api/workTicket";
import { mapGetters } from "vuex";
export default {
  props: {
    selectedList: {
      type: Array,
      default: [],
    },
    prjId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ticketBox: true,
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      searchColumns: [
        {
          prop: "ticketCode",
          type: "input",
          span: 3,
          placeholder: "请输入作业票编号",
        },
        {
          prop: "ticketType",
          span: 3,
          placeholder: "请选择作业票类型",
          type: "select",
          dicUrl:
            "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: "String",
        },
        {
          prop: "createUserName",
          type: "input",
          span: 3,
          placeholder: "请输入申请人",
        },
        {
          prop: "content",
          type: "input",
          span: 3,
          placeholder: "请输入作业票内容",
        },
        {
          type: "daterange",
          prop: "dateTime",
          span: 5,
        },
      ],
      selectionList: [],
    };
  },

  components: { GridLayout },

  computed: {
    ...mapGetters(["userInfo"]),
    option() {
      return {
        menu: false,
        selectable: (row, index) => {
          let ids = [];
          if(this.selectedList.length > 0) {
            this.selectedList.forEach((ele) => {
            ids.push(ele.id);
          });
          }
          return !ids.includes(row.id);
        },
        column: [
          {
            label: "作业票编号",
            prop: "ticketCode",
            align: "left",
            overHidden: true,
          },
          {
            label: "作业票类型",
            type: "select",
            align: "center",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            prop: "ticketType",
            overHidden: true,
          },
          {
            label: "作业内容",
            prop: "content",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业实施时间",
            prop: "workExecutionTime",
            align: "center",
            overHidden: true,
          },
          {
            label: "申请人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业负责人",
            prop: "workSupervisorName",
            align: "center",
            overHidden: true,
          },
          {
            label: "申请时间",
            prop: "createTime",
            align: "center",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "businessStatus",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=wpt_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },

  mounted() {
    this.onLoad(this.page);
  },

  methods: {
    // 列表查询
    gridHeadSearch(searchForm) {
      if (searchForm.dateTime && searchForm.dateTime.length > 0) {
        searchForm.startTime = searchForm.dateTime[0] + " 00:00:00";
        searchForm.endTime = searchForm.dateTime[1] + " 23:59:59";
      }
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置
    gridHeadEmpty(searchForm) {
      delete searchForm.dateTime;
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    onLoad(page, params = {}) {
      let that = this;
      that.page = page;
      that.loading = true;
      myWtTicketPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {
          type: 'all', // my个人相关的
          orgId: this.prjId ? this.prjId : this.userInfo.dept_id,
          createDept: this.prjId ? this.prjId: this.userInfo.dept_id
        })
      ).then((res) => {
        const data = res.data.data;
        that.page.total = data.total;
        that.$nextTick(() => {
          that.$refs.gridLayOut.page.total = data.total;
        });
        that.tableData = data.records;
        that.loading = false;
      });

      // ticketInventoryList(
      //   page.currentPage,
      //   page.pageSize,
      //   Object.assign(params, { organizationId: this.prjId?this.prjId:that.userInfo.dept_id })
      // ).then((res) => {
      //   const data = res.data.data;
      //   that.page.total = data.total;
      //   that.$nextTick(() => {
      //     that.$refs.gridLayOut.page.total = data.total;
      //   });
      //   that.tableData = data.records;
      //   that.loading = false;
      // });

    },
    closeDialog() {
      this.selectionList=[]
      // this.$emit("selectWorkTicket", "");
      this.ticketBox = !this.ticketBox;
    },
    submit() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      this.$emit("selectWorkTicket", this.selectionList);
      this.closeDialog();
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .el-dialog__body {
  padding: 10px 20px !important;
}
::v-deep .avue-crud .el-table {
  height: calc(100vh - 330px) !important;
  max-height: calc(100vh - 330px) !important;
}
</style>
